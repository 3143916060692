<template>
  <ResizeAuto ref="ResizeAuto">
    <template v-slot:default="{ resize }">
      <textarea
        :value="value"
        @input="update"
        @focus="resize"
        rows="1"
        v-bind:class="{ invalid: !state }"
        ref="StyledTextArea"
        :state="state"
        size="sm"
        :maxlength="limitInputLength()"
        no-auto-shrink
      >
      </textarea>
    </template>
  </ResizeAuto>
</template>

<script>
import ResizeAuto from "@/components/reusable/ResizeAuto";

export default {
  name: "StyledTextArea",
  components: {
    ResizeAuto,
  },
  props: {
    value: {
      type: String,
    },
    state: {
      type: Boolean,
      default: true,
    },
    aria_describedby: {
      type: String,
    },
    limited_words_amount: {
      type: Number,
    },
    max_answer_length_type: {
      type: Number,
    },
  },
  data() {
    return {
      limited_length: null,
      actual_length: null,
    };
  },
  methods: {
    update(event) {
      this.$emit("input", event.target.value);
      this.$refs.ResizeAuto.resize(event);
    },
    limitInputLength() {
      if (
        this.max_answer_length_type == 1 &&
        this.limited_words_amount != null
      ) {
        // let text = this.value.replace(/^ /, " ");
        // text = this.value.replace(/ $/, "");
        // text = this.value.replace(/[ ]+/g, "");
        let splittedTextArea = this.value.split(" ");
        let numberOfWords = splittedTextArea.length;
        this.actual_length = numberOfWords;
        if (this.actual_length == this.limited_words_amount + 1) {
          this.limited_length = this.value.length;
          this.$emit("maxWordsReached");
          return this.limited_length;
        } else {
          this.$emit("answerWordsChanged");
        }
        return null;
      } else {
        this.limited_length = this.limited_words_amount;
        return this.limited_length;
      }
    },
  },
  mounted() {
    this.limited_length = null;
    this.$nextTick(() => {
      this.$refs.StyledTextArea.focus();
      this.$refs.StyledTextArea.blur();
    });
  },
};
</script>

<style scoped>
textarea {
  --bottom-border-color: var(--kl-menu-color);
  width: 90%;
  border: 1px solid var(--secondary-color);
  resize: none;
  /* border-bottom: 1px solid var(--secondary-color); */
  padding: 3px 0.5em;
  margin: 3px 0.5em;
  overflow: hidden;
  border-radius: 3px;
  background-color: white;
  /* border-color: black; */
}
textarea:focus {
  outline: none !important;
  animation-name: example;
  animation-duration: 0.5s;
  border: none;
  border-bottom: 2px solid var(--bottom-border-color);
}
@keyframes example {
  from {
    border-bottom: 2px solid white;
  }
  to {
    border-bottom: 2px solid var(--bottom-border-color);
  }
}
.invalid {
  border: 1px solid red;
}
</style>